var Config = {
    "staging": {
        "title": {
            "en": "HelpDesk de Evertec: Help us Improve",
            "es": "HelpDesk de Evertec: Ayudanos a mejorar",
        },
        "prompt": {
            "en": "We appreciate evaluating the service received today",
            "es": "Agradecemos evalúe el servicio recibido el día de hoy"
        },
        "openQuestionPrompt": {
            "es": "Algún otro comentario:",
            "en": "Some other comment:"
        },
        "questions": {
            "Consultant": {
                "typeId": 1,
                "display_name": {
                    "en": "Products and Services: Bank Consultant / Host",
                    "es": "Productos y Servicios: Consultor Bancario / Anfitrión"
                },
                "title": {
                    "en": "Main reason for your evaluation",
                    "es": "Razón principal al evaluar su experiencia"
                },
                "cancel": {
                    "en": "Cancel",
                    "es": "Cancelar"
                },
                "options": [{
                        "id": 0,
                        "option": {
                            "en": "Wait Time",
                            "es": "Tiempo de Espera"
                        }
                    },
                    {
                        "id": 2,
                        "option": {
                            "en": "Courtesy",
                            "es": "Cortesía"
                        }
                    },
                    {
                        "id": 1,
                        "option": {
                            "en": "Handling your request / Orientation",
                            "es": "Manejo de su petición / Orientación"
                        }
                    },
                    {
                        "id": 3,
                        "option": {
                            "en": "Others",
                            "es": "Otros"
                        }
                    }
                ],
                "icon": "person"
            }
        },
        "thankyouText": {
            "en": "Thank you!",
            "es": "¡Gracias!"
        },
        "system": "faces",
        "timeouts": {
            "afterSurveySelection": 20000,
            "afterUploadDisabled": 10000
        },
        "languages": {
            "en": "English",
            "es": "Español"
        },
        "theme": "default",
        "branch": "Chicago",
        "ticket": {
            "prefix": "Ticket: ",
            "characters": "/[\\d\\w#-]/"
        },
        "typeText": {
            "en": "What service you received today?",
            "es": "¿Qué servicio recibió hoy?"
        },
        "labels": [{
                "en": "Like it!",
                "es": "¡Me Encantó!"
            },
            {
                "en": "Could be better",
                "es": "Puede Mejorar"
            },
            {
                "en": "Did not like it",
                "es": "No Me Gustó"
            }
        ],
        "startSurveyButton": {
            "en": "Start",
            "es": "Iniciar"
        },
        "startSurveyPrompt": {
            "en": "Let us know how was our service today",
            "es": "Agradecemos evalúe la experiencia de servicio del día de hoy"
        },
    },
    "bppr": {
        "title": {
            "en": "HelpDesk de Evertec: Help us Improve",
            "es": "HelpDesk de Evertec: Ayudanos a mejorar"
        },
        "navbarTitle": {
            "bold": {
                "en": "Type of Service: ",
                "es": "Tipo de Servicio: "
            },
            "normal": {
                "en": "Summary",
                "es": "Resumen"
            }
        },
        "prompt": {
            "en": "We appreciate evaluating the service received today",
            "es": "Agradecemos evalúe el servicio recibido el día de hoy"
        },
        "openAnswer": true,
        "openQuestionPrompt": {
            "es": "Algún otro comentario:",
            "en": "Some other comment:"
        },
        "questions": {
            "Consultant": {
                "typeId": 1,
                "display_name": {
                    "en": "Products and Services: Bank Consultant / Host",
                    "es": "Productos y Servicios: Consultor Bancario / Anfitrión"
                },
                "title": {
                    "en": "Main reason for your evaluation",
                    "es": "Razón principal al evaluar su experiencia"
                },
                "cancel": {
                    "en": "Cancel",
                    "es": "Cancelar"
                },
                "options": [{
                        "id": 0,
                        "option": {
                            "en": "Wait Time",
                            "es": "Tiempo de Espera"
                        }
                    },
                    {
                        "id": 2,
                        "option": {
                            "en": "Courtesy",
                            "es": "Cortesía"
                        }
                    },
                    {
                        "id": 1,
                        "option": {
                            "en": "Handling your request / Orientation",
                            "es": "Manejo de su petición / Orientación"
                        }
                    },
                    {
                        "id": 3,
                        "option": {
                            "en": "Others",
                            "es": "Otros"
                        }
                    }
                ],
                "icon": "person"
            }
        },
        "thankyouText": {
            "en": "Thank you!",
            "es": "¡Gracias!"
        },
        "thankYouButton": {
            "title": {
                "en": "Claim",
                "es": "Reclamación"
            },
            "description": {
                "en": "You can make a claim and your service will be investigated by our Quality Control unit",
                "es": "Puede realizar una reclamación y su servicio será investigado por nuestra unidad de Control de Calidad"
            },
            "url": {
                "en": "https://www.google.com",
                "es": "https://www.google.com"
            }
        },
        "system": "faces",
        "timeouts": {
            "afterSurveySelection": 20000,
            "afterUploadDisabled": 10000
        },
        "languages": {
            "en": "English",
            "es": "Español"
        },
        "theme": "default",
        "branch": "Chicago",
        "ticket": {
            "prefix": "Ticket: ",
            "characters": "/[\\d\\w#-]/"
        },
        "typeText": {
            "en": "What service you received today?",
            "es": "¿Qué servicio recibió hoy?"
        },
        "labels": [{
                "en": "Like it!",
                "es": "¡Me Encantó!"
            },
            {
                "en": "Could be better",
                "es": "Puede Mejorar"
            },
            {
                "en": "Did not like it",
                "es": "No Me Gustó"
            }
        ],
        "startSurveyButton": {
            "en": "Start",
            "es": "Iniciar"
        },
        "startSurveyPrompt": {
            "en": "Let us know how was our service today",
            "es": "Agradecemos evalúe la experiencia de servicio del día de hoy"
        }
    },
    "aws": {
        "prompt": {
            "fr": "Dans quelle mesure êtes-vous satisfait du service reçu aujourd'hui?",
            "en": "How satisfied are you with the service received today?",
            "es": "¿Cuan satisfecho esta con el servicio recibido hoy?"
        },
        "openAnswer": false,
        "openQuestionPrompt": {
            "es": "Algún otro comentario:",
            "en": "Some other comment:"
        },
        "questions": {
            "Consultor": {
                "typeId": 2,
                "display_name": {
                    "fr": "Produits et Services: Consultant bancaire / Hôte",
                    "en": "Products and Services: Banking Consultant / Host",
                    "es": "Productos y Servicios: Consultor Bancario / Anfitrion"
                },
                "title": {
                    "fr": "Principale raison de votre niveau de satisfaction:",
                    "en": "Main reason for your level of satisfaction:",
                    "es": "Razon principal de su nivel de satisfaccion:"
                },
                "cancel": {
                    "fr": "Annuler",
                    "en": "Cancel",
                    "es": "Cancelar"
                },
                "options": [{
                        "id": 1,
                        "option": {
                            "fr": "Heure de Fila",
                            "en": "Wait Time",
                            "es": "Tiempo en Fila"
                        }
                    },
                    {
                        "id": 3,
                        "option": {
                            "fr": "Courtoisie",
                            "en": "Courtesy",
                            "es": "Cortesia"
                        }
                    },
                    {
                        "id": 4,
                        "option": {
                            "fr": "Traitement de votre demande / Orientation",
                            "en": "Handling your request / Orientation",
                            "es": "Manejo de su peticion / Orientacion"
                        }
                    }
                ],
                "icon": "person"
            },
            "Teller": {
                "typeId": 1,
                "display_name": {
                    "fr": "Transactions en espèces: représentant bancaire",
                    "en": "Teller Transactions: Banking Representative",
                    "es": "Transacciones en Caja: Representante Bancario"
                },
                "title": {
                    "fr": "Principale raison de votre niveau de satisfaction:",
                    "en": "Main reason for your level of satisfaction:",
                    "es": "Razon principal de su nivel de satisfaccion:"
                },
                "cancel": {
                    "fr": "Annuler",
                    "en": "Cancel",
                    "es": "Cancelar"
                },
                "options": [{
                        "id": 1,
                        "option": {
                            "fr": "Heure de Fila",
                            "en": "Wait Time",
                            "es": "Tiempo en Fila"
                        }
                    },
                    {
                        "id": 2,
                        "option": {
                            "fr": "Traitement des transactions",
                            "en": "Transaction Processing",
                            "es": "Procesamiento de Transaccion"
                        }
                    },
                    {
                        "id": 3,
                        "option": {
                            "fr": "Courtoisie",
                            "en": "Courtesy",
                            "es": "Cortesia"
                        }
                    }
                ],
                "icon": "banking"
            }
        },
        "thankyouText": {
            "fr": "Merci Beaucoup",
            "en": "Thanks!",
            "es": "¡Gracias!"
        },
        "labels": [{
                "fr": "¡Moi Encantó!",
                "en": "I loved it!",
                "es": "¡Me Encanto!"
            },
            {
                "fr": "Neutre",
                "en": "Neutral",
                "es": "Neutral"
            },
            {
                "fr": "Je n'aime pas",
                "en": "I didn't like",
                "es": "No Me Gustó"
            }
        ],
        "system": "faces",
        "languages": {
            "fr": "French",
            "en": "English",
            "es": "Español"
        },
        "timeouts": {
            "afterSurveySelection": 10000,
            "afterUploadDisabled": 10000
        },
        "theme": "default",
        "branch": "Chicago",
        "startSurveyButton": {
            "fr": "Début",
            "en": "Start",
            "es": "Iniciar"
        },
        "startSurveyPrompt": {
            "fr": "Nous apprécions d'évaluer le service reçu aujourd'hui",
            "en": "We appreciate if you evaluate the service received today",
            "es": "Agradecemos evalue el servicio recibido en el día de hoy"
        },
        "ticket": {
            "prefix": "Ticket: ",
            "characters": "/[\\d\\w#-]/"
        },
        "typeText": {
            "fr": "Quel service avez-vous rendu aujourd'hui?",
            "en": "What service did you do today?",
            "es": "¿Que servicio realizo hoy?"
        }
    },
    "iedifiko": {
        "prompt": {
            "fr": "Dans quelle mesure êtes-vous satisfait du service reçu aujourd'hui?",
            "en": "How satisfied are you with the service received today?",
            "es": "¿Cuan satisfecho esta con el servicio recibido hoy?"
        },
        "openAnswer": false,
        "openQuestionPrompt": {
            "es": "Algún otro comentario:",
            "en": "Some other comment:"
        },
        "questions": {
            "Consultor": {
                "typeId": 2,
                "display_name": {
                    "fr": "Produits et Services: Consultant bancaire / Hôte",
                    "en": "Products and Services: Banking Consultant / Host",
                    "es": "Productos y Servicios: Consultor Bancario / Anfitrion"
                },
                "title": {
                    "fr": "Principale raison de votre niveau de satisfaction:",
                    "en": "Main reason for your level of satisfaction:",
                    "es": "Razon principal de su nivel de satisfaccion:"
                },
                "cancel": {
                    "fr": "Annuler",
                    "en": "Cancel",
                    "es": "Cancelar"
                },
                "options": [{
                        "id": 1,
                        "option": {
                            "fr": "Heure de Fila",
                            "en": "Wait Time",
                            "es": "Tiempo en Fila"
                        }
                    },
                    {
                        "id": 3,
                        "option": {
                            "fr": "Courtoisie",
                            "en": "Courtesy",
                            "es": "Cortesia"
                        }
                    },
                    {
                        "id": 4,
                        "option": {
                            "fr": "Traitement de votre demande / Orientation",
                            "en": "Handling your request / Orientation",
                            "es": "Manejo de su peticion / Orientacion"
                        }
                    }
                ],
                "icon": "person"
            },
            "Teller": {
                "typeId": 1,
                "display_name": {
                    "fr": "Transactions en espèces: représentant bancaire",
                    "en": "Teller Transactions: Banking Representative",
                    "es": "Transacciones en Caja: Representante Bancario"
                },
                "title": {
                    "fr": "Principale raison de votre niveau de satisfaction:",
                    "en": "Main reason for your level of satisfaction:",
                    "es": "Razon principal de su nivel de satisfaccion:"
                },
                "cancel": {
                    "fr": "Annuler",
                    "en": "Cancel",
                    "es": "Cancelar"
                },
                "options": [{
                        "id": 1,
                        "option": {
                            "fr": "Heure de Fila",
                            "en": "Wait Time",
                            "es": "Tiempo en Fila"
                        }
                    },
                    {
                        "id": 2,
                        "option": {
                            "fr": "Traitement des transactions",
                            "en": "Transaction Processing",
                            "es": "Procesamiento de Transaccion"
                        }
                    },
                    {
                        "id": 3,
                        "option": {
                            "fr": "Courtoisie",
                            "en": "Courtesy",
                            "es": "Cortesia"
                        }
                    }
                ],
                "icon": "banking"
            }
        },
        "thankyouText": {
            "fr": "Merci Beaucoup",
            "en": "Thanks!",
            "es": "¡Gracias!"
        },
        "labels": [{
                "fr": "¡Moi Encantó!",
                "en": "I loved it!",
                "es": "¡Me Encanto!"
            },
            {
                "fr": "Neutre",
                "en": "Neutral",
                "es": "Neutral"
            },
            {
                "fr": "Je n'aime pas",
                "en": "I didn't like",
                "es": "No Me Gustó"
            }
        ],
        "system": "faces",
        "languages": {
            "fr": "French",
            "en": "English",
            "es": "Español"
        },
        "timeouts": {
            "afterSurveySelection": 10000,
            "afterUploadDisabled": 10000
        },
        "theme": "default",
        "branch": "Chicago",
        "startSurveyButton": {
            "fr": "Début",
            "en": "Start",
            "es": "Iniciar"
        },
        "startSurveyPrompt": {
            "fr": "Nous apprécions d'évaluer le service reçu aujourd'hui",
            "en": "We appreciate if you evaluate the service received today",
            "es": "Agradecemos evalue el servicio recibido en el día de hoy"
        },
        "ticket": {
            "prefix": "Ticket: ",
            "characters": "/[\\d\\w#-]/"
        },
        "typeText": {
            "fr": "Quel service avez-vous rendu aujourd'hui?",
            "en": "What service did you do today?",
            "es": "¿Que servicio realizo hoy?"
        }
    },
    "triples": {
        "title": {
            "en": "Help us Improve! #TripleS",
            "es": "Ayudanos a mejorar! #TripleS",
        },
        "prompt": {
            "en": "In general, how would you rate the level of service provided by Triple S",
            "es": "En general, como usted clasificaria el nivel de servicio provisto por Triple S"
        },
        "openQuestionPrompt": {
            "es": "Algún otro comentario:",
            "en": "Additional Comments:"
        },
        "questions": {
            "Consultant": {
                "typeId": 1,
                "display_name": {
                    "en": "Main reason behind your level of satisfaction",
                    "es": "Razón principal por la cual proveyó este nivel de satisfación (esp)"
                },
                "title": {
                    "en": "Main reason for your evaluation",
                    "es": "Razón principal al evaluar su experiencia"
                },
                "cancel": {
                    "en": "Cancel",
                    "es": "Cancelar"
                },
                "options": [{
                        "id": 0,
                        "option": {
                            "en": "Friendliness",
                            "es": "Amabilidad"
                        }
                    },
                    {
                        "id": 2,
                        "option": {
                            "en": "Technical abilities",
                            "es": "Nivel tecnico"
                        }
                    },
                    {
                        "id": 1,
                        "option": {
                            "en": "Solving Time",
                            "es": "Tiempo de manejo"
                        }
                    }
                ],
                "icon": "person"
            }
        },
        "thankyouText": {
            "en": "Thank you for your feedback!",
            "es": "¡Gracias por su participacion!"
        },
        "system": "faces",
        "timeouts": {
            "afterSurveySelection": 20000,
            "afterUploadDisabled": 10000
        },
        "languages": {
            "en": "English",
            "es": "Español"
        },
        "theme": "default",
        "branch": "Chicago",
        "ticket": {
            "prefix": "Ticket: ",
            "characters": "/[\\d\\w#-]/"
        },
        "typeText": {
            "en": "What service you received today?",
            "es": "¿Qué servicio recibió hoy?"
        },
        "labels": [{
                "en": "Like it!",
                "es": "¡Me Encantó!"
            },
            {
                "en": "Neutral",
                "es": "Neutral"
            },
            {
                "en": "Did not like it",
                "es": "No Me Gustó"
            }
        ],
        "startSurveyButton": {
            "en": "Start",
            "es": "Iniciar"
        },
        "startSurveyPrompt": {
            "en": "Let us know how was our service today",
            "es": "Agradecemos evalúe la experiencia de servicio del día de hoy"
        },
    },
    "orientalbank": {
        "title": {
            "en": "Help us Improve!",
            "es": "Ayudanos a mejorar!"
        },
        "prompt": {
            "en": "How would you classify the service received today?",
            "es": "¿Cómo clasificas el servicio recibido hoy?"
        },
        "openAnswer": true,
        "openQuestionPrompt": {
            "es": "Algún otro comentario:",
            "en": "Additional Comments:"
        },
        "questions": {
            "Teller": {
                "typeId": 7,
                "display_name": {
                    "en": "Service by Teller",
                    "es": "Servicio por cajero"
                },
                "title": {
                    "en": "Main reason when evaluating your experience",
                    "es": "Razón principal al evaluar tu servicio"
                },
                "cancel": {
                    "en": "Cancel",
                    "es": "Cancelar"
                },
                "options": [{
                    "id": 1,
                    "option": {
                        "en": "Waiting Time",
                        "es": "Tiempo de Espera"
                    }
                }, {
                    "id": 3,
                    "option": {
                        "en": "Courtesy",
                        "es": "Cortesía"
                    }
                }, {
                    "id": 2,
                    "option": {
                        "en": "Transaction handling",
                        "es": "Manejo de Transacción"
                    }
                }],
                "icon": "person"
            },
            "Servicio": {
                "typeId": 8,
                "display_name": {
                    "en": "Banking consulting",
                    "es": "Consultoría bancaria"
                },
                "title": {
                    "en": "Main reason when evaluating your experience",
                    "es": "Razón principal al evaluar tu servicio"
                },
                "cancel": {
                    "en": "Cancel",
                    "es": "Cancelar"
                },
                "options": [{
                    "id": 1,
                    "option": {
                        "en": "Waiting Time",
                        "es": "Tiempo de Espera"
                    }
                }, {
                    "id": 4,
                    "option": {
                        "en": "Solution provided",
                        "es": "Solución provista"
                    }
                }, {
                    "id": 3,
                    "option": {
                        "en": "Courtesy",
                        "es": "Cortesía"
                    }
                }],
                "icon": "banking"
            },
            "Auto Banco": {
                "typeId": 9,
                "display_name": {
                    "en": "Drive-thru service",
                    "es": "Servicio por autobanco"
                },
                "title": {
                    "en": "Main reason when evaluating your experience",
                    "es": "Razón principal al evaluar tu servicio"
                },
                "cancel": {
                    "en": "Cancel",
                    "es": "Cancelar"
                },
                "options": [{
                    "id": 1,
                    "option": {
                        "en": "Waiting Time",
                        "es": "Tiempo de Espera"
                    }
                }, {
                    "id": 3,
                    "option": {
                        "en": "Courtesy",
                        "es": "Cortesía"
                    }
                }, {
                    "id": 2,
                    "option": {
                        "en": "Transaction handling",
                        "es": "Manejo de Transacción"
                    }
                }],
                "icon": "person"
            },
            "ATM": {
                "typeId": 10,
                "display_name": {
                    "en": "ATM",
                    "es": "ATM"
                },
                "title": {
                    "en": "Main reason when evaluating your experience",
                    "es": "Razón principal al evaluar tu servicio"
                },
                "cancel": {
                    "en": "Cancel",
                    "es": "Cancelar"
                },
                "options": [{
                    "id": 1,
                    "option": {
                        "en": "The ATM is available",
                        "es": "Está disponible el cajero"
                    }
                }, {
                    "id": 3,
                    "option": {
                        "en": "I can easily find ATMs",
                        "es": "Puedo encontrar cajeros automáticos fácilmente"
                    }
                }, {
                    "id": 2,
                    "option": {
                        "en": "The ATM is in excellent condition",
                        "es": "El cajero está en óptimas condiciones"
                    }
                }],
                "icon": "banking"
            }
        },
        "extraQuestion": {
            "display_name": {
                "en": "Additional Question",
                "es": "Pregunta Adicional"
            },
            "title": {
                "en": "The service received was for your relationship: ",
                "es": "El servicio recibido era para tu relación: "
            },
            "cancel": {
                "en": "Cancel",
                "es": "Cancelar"
            },
            "options": [{
                "id": "Personal",
                "option": {
                    "en": "Personal",
                    "es": "Personal"
                }
            }, {
                "id": "Commercial",
                "option": {
                    "en": "Commercial",
                    "es": "Comercial"
                }
            }]
        },
        "thankyouText": {
            "en": "Thank you for your answer!",
            "es": "¡Gracias por tu respuesta!"
        },
        "labels": [{
                "en": "Like it!",
                "es": "¡Me Encantó!"
            },
            {
                "en": "Neutral",
                "es": "Neutral"
            },
            {
                "en": "Did not like it",
                "es": "No Me Gustó"
            }
        ],
        "system": "faces",
        "languages": {
            "en": "English",
            "es": "Español"
        },
        "timeouts": {
            "afterSurveySelection": 20000,
            "afterUploadDisabled": 10000
        },
        "theme": "default",
        "branch": "SanJuan",
        "startSurveyButton": {
            "en": "Start",
            "es": "Iniciar"
        },
        "startSurveyPrompt": {
            "en": "Let us know how was our service today",
            "es": "Agradecemos evalúe la experiencia de servicio del día de hoy"
        },
        "ticket": {
            "prefix": "Ticket: ",
            "characters": "/[\\d\\w#-]/"
        },
        "typeText": {
            "en": "What service did you receive today?",
            "es": "¿Qué servicio recibiste hoy?"
        }
    }
    
};

export default Config
