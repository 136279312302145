import React, { Component } from 'react';

class OpenQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    onChange(e){
        this.setState({value: e.currentTarget.value});
    }

    onClick(){
        this.props.select(this.props.name, this.state.value || '');
    }

    render() {
        return (
            <div className="columns">
                <div className="column">
                    <textarea className="textarea" style={{ marginBottom: 15 }} onChange={this.onChange}></textarea>
                    <button className="button is-fullwidth" onClick={this.onClick}>
                        { this.props.buttonText }
                    </button>
                </div>
            </div>
        );
    }
}

export default OpenQuestion;
