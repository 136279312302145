import React, { Component } from 'react';

class Faces extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="session">
                <div className="columns is-mobile">
                    <div className="column">
                        <img src={`/images/faceGreen.png`} alt="Green" width="80%" onClick={() => this.props.select("rating", 2)}/>
                    </div>
                    <div className="column">
                        <img src={`/images/faceOrange.png`} alt="Orange" width="80%" onClick={() => this.props.select("rating", 1)}/>
                    </div>
                    <div className="column">
                        <img src={`/images/faceRed.png`} alt="Red" width="80%" onClick={() => this.props.select("rating", 0)}/>
                    </div>
                </div>
                <div className="columns is-mobile">
                    { this.props.labels.map((text, idx) =>
                    <div key={idx} className="column">
                        <h1 className="subtitle is-6"> {text} </h1>
                    </div>
                    )}
                </div>
            </div>
        )
    }
}

export default Faces;
