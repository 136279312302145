
// fetch api constants
export const HOST_PREFIX = 'https://';
export const HOST_SUFFIX = '.damefeedback.com/';
export const NEW_HOST_URL = 'https://pf42lr9w68.execute-api.us-east-1.amazonaws.com/demo/';
export const STAGING_HOST_URL = 'https://kl5p5es2v6.execute-api.us-east-1.amazonaws.com/staging/';
// export const CONFIGS_URL = 'configs';
export const CONFIGS_URL = 'https://akcelita-artsense.s3.amazonaws.com/damefeedback/configs';

export const DEVICES = {
    121: 'T8784115 SDM Aguadilla',
    122: 'T8784015 SDM Aguadilla',
    124: 'T8782287 SDM Aibonito',
    127: 'T8782733 SDM Auxilio Mutuo',
    128: 'T8782278 SDM Minillas',
    129: 'T8782260 SDM Bayamon 167',
    130: 'T8784009 SDM Betances',
    131: 'T8784109 SDM Betances',
    132: 'T8782227 SDM Plaza del Sol',
    134: 'T8782228 SDM Las Catalinas',
    138: 'T8782701 Hotel Caguas Real',
    139: 'T8784011 SDM Villa Blanca',
    142: 'T8782273 SDM Colobos',
    143: 'T8782205 SDM Escorial',
    144: 'T8782265 SDM Catano',
    146: 'T8782271 SDM Cayey',
    149: 'T8782707- Bayamon CT Radiology',
    151: 'T8784007 SDM Cupey',
    152: 'T8782740 SDM Econo San Lorenzo',
    153: 'T8784112 SDM Fajardo',
    154: 'T8784012 SDM Fajardo',
    155: 'T8782742 SDM  Fcia Zayas Coamo',
    156: 'T8782218 SDM Guayama',
    157: 'T8782206 SDM San Patricio',
    159: 'T8782364 SDM Santa Maria',
    160: 'T8784105 SDM Plaza Guaynabo',
    161: 'T8784005 SDM Plaza Guaynabo',
    162: 'T8782222 SDM Hatillo',
    163: 'T8784001 SDM Hato Rey Pinero',
    164: 'S8784101 Hato Rey Pinero',
    165: 'T8784201 SDM Hato Rey Pinero',
    166: 'T8782757 Hotel Marriott-Aloft',
    167: 'T8782216 SDM Humacao',
    168: 'T8782316 SDM Humacao',
    169: 'T8782286 SDM Juana Diaz',
    170: 'T8782213 SDM Las Piedras',
    171: 'T8782266 SDM Los Paseos',
    172: 'T8782809 Mall of San Juan',
    174: 'T8784116 SDM Manati',
    175: 'T8784016 SDM Manati',
    176: 'T8782724- Mapfre',
    177: 'T8782255 Mayaguez Main',
    179: 'T8782220 SDM Mayaguez Mall',
    180: 'T8782729 Mayaguez Mall',
    181: 'T8784014 SDM Mayaguez Mall',
    183: 'T8782288 SDM Penuelas',
    184: 'T8782388 Penuelas',
    185: 'T8784103 SDM Plaza Carolina',
    186: 'S8784203- Plaza Carolina',
    187: 'T8784003 SDM Plaza Carolina',
    189: 'T8784013 SDM Plaza del Caribe 2',
    193: 'T8782732 SDM Kiosko Plaza del Sol',
    198: 'T8782370 SDM Ponce Plaza del Caribe',
    199: 'T8782221 SDM Ponce Rambla',
    200: 'T8782258 SDM Hato Rey Ponce de Leon',
    201: 'T8782280 SDM Ponce las Americas',
    202: 'T8782282 SDM Ponce Plaza',
    203: 'T8782741- Puma Arecibo',
    205: 'T8784210 SDM Rexville',
    206: 'T8784010 SDM Rexville ',
    207: 'T8782737 SDM kiosko Rio Hondo',
    208: 'T8782236 SDM Condado',
    209: 'T8782202 SDM Oriental Center',
    210: 'T8782269 SDM Plaza las Americas',
    211: 'T8782369 SDM Plaza las Americas',
    212: 'T8782366 SDM Los Paseos',
    214: 'T8782211 SDM San Lorenzo',
    221: 'T8784008 SDM Trujillo Alto',
    222: 'T8784017 SDM Vega Baja',
    223: 'T8782212- Yabucoa',
    224: 'T8782283 SDM Yauco',
    225: 'T8782759 SDM Plaza Isabela',
    227: 'T8784202 ITM Oriental Center',
    228: 'T8784302 ITM Oriental Center',
    229: 'T8784327 ITM Plaza del Sol',
    230: 'T8784227 ITM Plaza del Sol',
    232: 'T8784328 ITM Las Catalinas',
    233: 'T8784264 ITM Santa Maria ',
    234: 'T8784269 ITM Plaza las Americas',
    235: 'T8784369 ITM Plaza las Americas',
    236: 'T8784469 ITM Plaza las Americas',
    237: 'T8784270 ITM Plaza del Caribe',
    238: 'T8784771 ITM Plaza Cayey',
    239: 'T8784737 ITM Kiosko Rio Hondo',
    240: 'T8784211 ITM Villa Blanca ',
    241: 'T8784732 ITM Kiosko Plaza del Sol',
    242: 'T8782706 SDM Kiosko San Patricio',
    243: 'T8782738 SDM-Kiosko Canovanas',
    244: 'T8782804 SDM St John',
    246: 'T8784213 ITM Plaza del Caribe Branch 2 ',
    247: 'T8784706 ITM Kiosko San Patricio',
    248: 'T8784738 ITM Kiosko Canovanas ',
    249: 'T8785027 ITM St Thomas',
    250: 'T8785028 ITM St Croix',
    251: 'T8784278 ITM-Minillas',
    252: 'T8782739 SDM-Coamo',
    253: 'T8784219 ITM-Dorado',
    290: 'T8784378 ITM Minillas',
    291: 'T8784745 ITM Kiosko Las Catalinas',
    292: 'T8784747 ITM Manati Monte Real ',
    293: 'T8784414- ITM Mayaguez Mall 2',
    294: 'T8784746 ITM Kiosko Montehiedra ',
    295: 'T8784412 ITM Fajardo',
    296: 'T8784205- ITM Escorial',
    297: 'T8784027- SDM St Thomas',
    298: 'T8784028- SDM St Croix',
    299: 'T8782745- SDM Kiosko Las Catalinas',
    300: 'T8782746- SDM Kiosko Montehiedra',
    301: 'T8784107- SDM Cupey',
    302: 'T8782380- SDM Ponce las Americas',
    303: 'T8784754- ITM Hospital Ashford Condado',
    304: 'T8784748 ITM  Plaza del Norte Hatillo',
    305: 'T8784273 ITM Los Colobos',
    306: 'T8784403 ITM Plaza Carolina',
    307: 'T8784503 ITM Plaza Carolina',
    308: 'T8784749 ITM Palma Real',
    309: 'T8784218 ITM Guayama',
    310: 'T8784753 ITM Caguas Plaza Centro',
    311: 'T8784755 ITM San Patricio Mall',
    312: 'T8782767 SuperMax VSJ',
    313: 'T8784206 ITM San Patricio Branch',
    314: 'T8784514 ITM Mayaguez Mall 2'
}


//url for browser:
//http://localhost:3000/#i=bppr&d=240
//http://localhost:3000/#i=bppr&d=56&t=1  si se quiere eliminar el step de el type