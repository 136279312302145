import React from 'react';

function UnsupportedBrowserMessage(){
    return (
        <section className="hero is-fullheight is-default is-bold">
            <div className="hero-head">
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <div className="columns is-centered">
                            <div className="column is-8">
                            <div className="columns">
                                <div className="column">
                                    <h1 className="title is-3"> Upgrade browser for full DameFeedback experience </h1>
                                </div>
                            </div>
                            <div className="columns is-centered">
                                <div className="column is-8">
                                    <h1 className="subtitle is-6">
                                        It looks like you may be using a web browser version that
                                        we don't support. Make sure you're using the most recent version of your browswe,
                                        or try using one of these supported browsers, to get the full DameFeedback experience
                                    </h1>
                                </div>
                            </div>
                            <div className="columns is-centered">
                                <div className="column">
                                    <figure class="image is-128x128">
                                        <img alt="CHROME" src="https://github.com/alrra/browser-logos/raw/master/src/chrome/chrome_128x128.png"/>
                                    </figure>
                                    CHROME
                                </div>
                                <div className="column">
                                    <figure class="image is-128x128">
                                        <img alt="FIREFOX" src="https://github.com/alrra/browser-logos/raw/master/src/firefox/firefox_128x128.png"/>
                                    </figure>
                                    FIREFOX
                                </div>
                                <div className="column">
                                    <figure class="image is-128x128">
                                        <img alt="EXPLORER" src="http://icons.iconarchive.com/icons/tatice/cristal-intense/256/Internet-Explorer-icon.png"/>
                                    </figure>
                                    INTERNET EXPLORER 11
                                </div>
                                <div className="column is-small">
                                    <figure class="image is-128x128">
                                        <img alt="EDGE" src="https://github.com/alrra/browser-logos/raw/master/src/edge/edge_128x128.png"/>
                                    </figure>
                                    EDGE
                                </div>
                                <div className="column">
                                    <figure class="image is-128x128">
                                        <img alt="SAFARI" src="https://github.com/alrra/browser-logos/raw/master/src/safari/safari_128x128.png"/>
                                    </figure>
                                    SAFARI
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default UnsupportedBrowserMessage;
