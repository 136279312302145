import React from 'react';

const Loading = ({ background }) => (
  <section
    className="hero is-fullheight"
    style={{
      backgroundImage: `url("${background}")`,
      backgroundSize: 'cover',
      color: '#FFF'
    }}
  >
    <div className="hero-body">
      <div className="container has-text-centered">
        <div className="lds-dual-ring" />
      </div>
    </div>
  </section>
);

export default Loading;
