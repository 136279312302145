import React, { Component } from 'react';

class Stars extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="session">
                <div className="columns is-mobile">
                    <div className="column">
                        <img src={`/images/star.png`} alt="star1" width="80%" onClick={() => this.props.select("rating", 0)}/>
                    </div>
                    <div className="column">
                        <img src={`/images/star.png`} alt="star2" width="80%" onClick={() => this.props.select("rating", 1)}/>
                    </div>
                    <div className="column">
                        <img src={`/images/star.png`} alt="star3" width="80%" onClick={() => this.props.select("rating", 2)}/>
                    </div>
                    <div className="column">
                        <img src={`/images/star.png`} alt="star4" width="80%" onClick={() => this.props.select("rating", 3)}/>
                    </div>
                    <div className="column">
                        <img src={`/images/star.png`} alt="star5" width="80%" onClick={() => this.props.select("rating", 4)}/>
                    </div>
                </div>
                <div className="columns is-mobile">
                    <div className="column">
                        <h1 className="subtitle is-6"> No Me Gusto </h1>
                    </div>
                    <div className="column">
                        <h1 className="subtitle is-6"> Neutral </h1>
                    </div>
                    <div className="column">
                        <h1 className="subtitle is-6"> Me Encanto </h1>
                    </div>
                </div>
            </div>
        )
    }
}

export default Stars;
