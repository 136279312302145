import React from 'react';

function AppLayout({
    css,
    hideLanguageBar,
    languages,
    setLanguage,
    title,
    navbarTitle,
    children,
}){

    return (
        <section className="hero is-fullheight is-default is-bold">
            {css && <style>{css}</style>}
            {!hideLanguageBar && (
            <div className="hero-head">
                <nav className="navbar">
                    <div className="container">
                        <div className="navbar-brand">
                            <div className="navbar-item">
                                <div className="field has-addons">
                                    {Object.keys(languages).map((code) => (
                                        <p className="control" key={code}>
                                            <button className="button" onClick={() => setLanguage(code)}>
                                                <span>{languages[code]}</span>
                                            </button>
                                        </p>
                                    ))}
                                </div>
                            </div>
                            {navbarTitle && (
                                <div className="navbar-item">
                                    <h3 style={{ marginTop: -6 }}>
                                        <span className="title is-3">{navbarTitle.bold || ''}</span>
                                        <span className="subtitle is-3">{navbarTitle.normal || ''}</span>
                                    </h3>
                                </div>
                            )}
                        </div>
                    </div>
                </nav>
            </div>
            )}
            <div className="hero-body">
                <div className="container has-text-centered">
                    <div className="columns">
                        <div className="column">
                            <h1 className="title is-4"> {title} </h1>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            {children}
                        </div>
                    </div>
                </div>
            </div>

            <div className="hero-foot" style={{marginBottom: "120px"}}>
                <div className="container has-text-centered">
                    <div className="session">
                        <a className="subtitle is-6" href="http://www.damefeedback.com/"> Powered by </a>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <img src="https://s3.amazonaws.com/akcelita-artsense/images/logo-damefeedback.png" height="28" alt="logo" width="80px"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AppLayout;
