import React from 'react'
import './styles.css'

const ThankYouSection = ({ config, language, help_url }) => {
	return (
		<div>
			<h1 className="title is-4"> {config.thankyouText[language]} </h1>
			{config.thankYouButton && (
				<div className="thankYou--buttonSection">
					{config.thankYouButton.description && (
						<div className="ml-4 thankYou--buttonDescriptionContainer">
							<h3
								className="title is-4 thankYou--buttonDescription"
							>
								{config.thankYouButton.description[language]}
							</h3>
						</div>
					)}
					<a
						className="button is-large thankYou--button"
						target="_blank"
						href={help_url || config.thankYouButton.url[language]}
					>
						{config.thankYouButton.title[language]}
					</a>
				</div>
			)}
		</div>
	)
}

export default ThankYouSection
