import React, { Component } from 'react';

class Type extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        console.log("This.props.options", this.props.options)
        return (
            <div className="session">
                <div className="columns">
                    {
                        Object.keys(this.props.options).map((option) =>
                        <div className="column" key={this.props.options[option].typeId}>
                            <img src={`/images/${option}.png`} alt={option} width="40%" />
                            <button className="button is-fullwidth button-big" onClick={(e) => {
                                this.props.select({
                                    "type_selected": this.props.options[option],
                                    "typeId": this.props.options[option].typeId
                                });
                            } }>
                                { this.props.options[option].display_name[this.props.language] }
                            </button>
                        </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default Type;
