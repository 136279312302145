import React, { Component } from 'react';

class Numbers extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <div className="columns is-mobile">
                    <div className="column">
                        <img src={`/images/faceGreen.png`} alt="Green" />
                    </div>
                    <div className="column">
                        <img src={`/images/faceOrange.png`} alt="Orange" />
                    </div>
                    <div className="column">
                        <img src={`/images/faceRed.png`} alt="Red" />
                    </div>
                    <div className="column">
                        <img src={`/images/faceGreen.png`} alt="Green" />
                    </div>
                    <div className="column">
                        <img src={`/images/faceOrange.png`} alt="Orange" />
                    </div>
                    <div className="column">
                        <img src={`/images/faceGreen.png`} alt="Green" />
                    </div>
                </div>
                <div className="columns is-mobile">
                    <div className="column">
                        <img src={`/images/faceOrange.png`} alt="Orange" />
                    </div>
                    <div className="column">
                        <img src={`/images/faceRed.png`} alt="Red" />
                    </div>
                    <div className="column">
                        <img src={`/images/faceGreen.png`} alt="Green" />
                    </div>
                    <div className="column">
                        <img src={`/images/faceOrange.png`} alt="Orange" />
                    </div>
                    <div className="column">
                        <img src={`/images/faceRed.png`} alt="Red" />
                    </div>
                </div>
            </div>
        )
    }
}

export default Numbers;
