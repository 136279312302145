import React, { Component } from 'react';

class Thumbs extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="columns is-mobile">
                <div className="column">
                    <img src={`/images/thumbUp.png`} alt="Up" width="50%" onClick={() => this.props.select("rating", 0)}/>
                </div>
                <div className="column">
                    <img src={`/images/thumbDown.png`} alt="Down" width="50%" onClick={() => this.props.select("rating", 1)}/>
                </div>
            </div>
        )
    }
}

export default Thumbs;
