import React, { Component } from 'react';

import Faces from './Faces';
import Faces5 from './Faces5';
import Stars from './Stars';
import Thumbs from './Thumbs';
import Numbers from './Numbers';

const SURVEY_TYPES = {
    stars: Stars,
    faces: Faces,
    faces5: Faces5,
    thumb: Thumbs,
    numbers: Numbers
}

class Survey extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const SurveyType = SURVEY_TYPES[this.props.system.toLowerCase()];
        if(SurveyType){
            return <SurveyType select={this.props.select} labels={this.props.labels}/>;
        } else {
            return <div></div>;
        }
    }
}

export default Survey;
