import React, { Component } from 'react';

class Faces5 extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
        <div className="session">
            <div className="columns is-mobile">
                <div className="column">
                    <img src={`/images/faces5-angry.png`} alt="angry" width="80%" onClick={() => this.props.select("rating", 4)}/>
                </div>
                <div className="column">
                    <img src={`/images/faces5-sad.png`} alt="sad" width="80%" onClick={() => this.props.select("rating", 3)}/>
                </div>
                <div className="column">
                    <img src={`/images/faces5-meh.png`} alt="meh" width="80%" onClick={() => this.props.select("rating", 2)}/>
                </div>
                <div className="column">
                    <img src={`/images/faces5-happy.png`} alt="happy" width="80%" onClick={() => this.props.select("rating", 1)}/>
                </div>
                <div className="column">
                    <img src={`/images/faces5-happiest.png`} alt="happiest" width="80%" onClick={() => this.props.select("rating", 0)}/>
                </div>
            </div>
            <div className="columns is-mobile">
                <div className="column">
                    <h1 className="subtitle is-6"> No Me Gusto </h1>
                </div>
                <div className="column">
                    <h1 className="subtitle is-6"> Neutral </h1>
                </div>
                <div className="column">
                    <h1 className="subtitle is-6"> Me Encanto </h1>
                </div>
            </div>
        </div>
        )
    }
}

export default Faces5;
